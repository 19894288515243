<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/.el-select .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  /deep/.el-input__inner {
    height: 30px;
    line-height: 30px;
    border-color: rgb(23, 118, 210);
  }

  .table td {
    line-height: 20px;
  }

  .white-space-nowrap {
    white-space: nowrap;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header.vue";
  import Vue from "vue";
  import {
    getPayList,
    createOnlineTrade,
    getOnlineTrade,
    getRefundTrade,
    tradeQuery,
    needCallbackStatus,
    stratTrade,
  UpdateOnlineTrade
  } from "@/api/pay/pay.js"
  import payConditionHeader from "@/components/payCondition-header";

  /**
   * 支付管理--订单
   */
  export default {
    components: {
      Layout,
      PageHeader,
      payConditionHeader


    },
    data() {
      return {
        title: "支付订单查询",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "支付管理",
            active: true
          }
        ],
        exportTitle: "支付订单导出",
        module: "ZFDDGL",
        showmodal: false,
        bdmodal: false,
        tableList: [],
        refundList: [],
        yczt: [{
            name: "所有",
            val: "0",

          },
          {
            name: "重复缴费（报名号相同）",
            val: "1",

          },
          {
            name: " 重复缴费（报名号不同）",
            val: "2",

          },
          {
            name: " 掉单",
            val: "3",

          }
        ],
        zfzt: [{
            name: "已退款交易已关闭",
            val: "-1",

          },
          {
            name: "未支付",
            val: "0",

          },
          {
            name: "已支付",
            val: "1",

          }
        ],
        zffs: [{
            name: "微信",
            val: "01"
          },
          {
            name: "支付宝",
            val: "02"
          },
          {
            name: "易宝",
            val: "03"
          },
          {
            name: "人工收费_现金",
            val: "04"
          },
          {
            name: " 企业汇款_单位",
            val: "06"
          },
          {
            name: " 减免",
            val: "05"
          },
          {
            name: " 合利宝支付",
            val: "07"
          },
        ],
        bdzffs: [{
            name: "微信",
            val: "01"
          },
          {
            name: "支付宝",
            val: "02"
          },
          {
            name: "易宝",
            val: "03"
          },
          {
            name: " 合利宝支付",
            val: "07"
          }
        ],
        bdgxfs: [{
            name: "更新订单+业务",
            val: "trade"
          },
          {
            name: "仅更新业务",
            val: "business"
          },
        ],
        bdzt: [{
            name: "所有",
            val: ""
          },
          {
            name: "已补单",
            val: "1"
          },
          {
            name: "已支付未补单",
            val: "2"
          },
          {
            name: "未支付不补单",
            val: "3"
          },
        ],
        years: [],
        refundDdh: "",
        isIndeterminate: false,
        checkList: [],
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          nf: new Date().getFullYear(),
          zffs: "",
          kssj: "",
          jssj: "",
          keyword: "",
          bdzt: "",
        },
        // 补单
        pageData2: {
          cxlx: "",
          type: "",
        },
      };
    },
    methods: {
      getList() {
        getPayList(this.pageData).then(res => {
          if (res.status) {
            if (res.status) {
              this.tableList = res.data
              this.pageData.total = res.total
            }
          }
        })
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页
        this.getList() //渲染数据的函数
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },

      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
      // 在线退费
      OnlineRefund(ddh) {
        var timer3= null;
        createOnlineTrade(ddh).then(res => {
          if (res.status) {
            let reData = res.data.refund
            let refundObj = {
              outRefundNo: reData.tkdh,
              outTradeNo: reData.ddh,
              tklx: reData.tklx,
              shbm: reData.shbh,
            }

            timer3 = setInterval(
            getOnlineTrade(refundObj).then(res1 => {
              if (res1.data && res1.data.refundStatus) {
                this.$message({
                  type: 'success',
                  message: '退款成功!'
                });
                clearInterval(timer3);
              }
            }), 3000)
          }
        })
      },
      // 退费订单
      getReund(ddh) {
        this.showmodal = true
        this.refundDdh = ddh
        var timer2 = setInterval(getRefundTrade({
          ddh: ddh
        }).then(res => {
          if (res.status && res.data) {
            this.refundList = res.data
          }
          clearInterval(timer2);
        }), 3000)

      },
      // 全选
      checkAll(e) {
        // let newList = this.tableList.filter(k => {
        //   return k.zfzt == '0'
        // })
        this.checkList = e ? e : [],
          this.isIndeterminate = e
      },


      // 选择
      chooseTrade() {

        console.log(this.checkList)
      },
      // 查单
      searchBuDan() {
        if (this.checkList.length) {
          if (this.pageData2.cxlx) {
            this.bdmodal = true
            let bdObj = {
              zffs: this.pageData2.cxlx
            }
            this.get(bdObj, 0)
          } else {
            this.$message({
              type: 'warning',
              message: '请选择查询类型!'
            });
          }
        } else {
          this.$message({
            type: 'warning',
            message: '请选择需要查询的数据!'
          });
        }

      },

      get(bdObj, index) {
        let list = this.checkList
        if (index >= list.length) {
          return
        }
        let k = list[index]
        bdObj.outTradeNo = k.ddh
        bdObj.shbm = k.shbm
        let that = this
        tradeQuery(bdObj).then(res => {
          if (res.data.tradeStatus) {
            let news = {}
            news.jyh = res.data.ddjyh
            news.ddje = res.data.ddje
            news.ddzfsj = res.data.ddzfsj
            news.jyzt = 1
            news = {
              ...that.checkList[index],
              ...news
            }
            Vue.set(this.checkList, index, news)
            that.buDanPost(bdObj, res.data.ddjyh, index)
            return res.data
          } else {
            that.get(bdObj, index + 1)
            return {}
          }

        })

      },

      buDanPost(obj, val, index) {
        return needCallbackStatus({
          ...obj,
          tradeNo: val
        }).then(res1 => {
          if (!res1.status) {
            this.$message({
              type: 'info',
              message: res1.message
            });
          }
          this.get(obj, index + 1)

        })
      },
      // 开始批量补单
      startBudan() {
        let type = this.pageData2.type
        let outTradeNo

        if (this.checkList.length) {
          if (type) {
            this.checkList.forEach(v => {
              outTradeNo = v.ddh
              stratTrade(type, outTradeNo).then(res => {
                if (res.status) {
                  this.$message({
                    type: 'success',
                    message: '批量补单成功!'
                  });
                  this.$forceUpdate()
                  this.checkList = []
                }
              })
            })
          } else {
            this.$message({
              type: 'warning',
              message: '请选择补单类型!'
            });
          }
        } else {
          this.$message({
            type: 'warning',
            message: '请选择需补单的数据!'
          });
        }




      },
        //查询退款信息
      getSearch(obj) {
        let refundObj = {
          outRefundNo: obj.tkdh,
          outTradeNo: obj.ddh,
          tklx: obj.tklx,
          shbm: obj.shbh,
        }
        getOnlineTrade(refundObj).then(res1 => {
          if (res1.data && res1.data.refundStatus) {
            this.$confirm('该退款单号已退费成功，是否更新订单的退费状态为已退费？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              UpdateOnlineTrade(refundObj).then(res => {
                if (res.status) {
                  this.$message({
                    type: 'success',
                    message: '状态更新成功!'
                  });
                }
                this.getReund(obj.ddh)
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消更新'
              });
            });


          }
        })

      }
    },
    mounted() {
      this.getYear()
      this.getList()
    },

  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 500px;">
          <div class="card-body">
            <div class=" check-table-top border-dash pb-3 mb-3 ">
              <div class="flexList" style="flex: 1;">
                <payConditionHeader :pageData="pageData"></payConditionHeader>
                <el-select class=" mr-2 " style="width: 9%;" v-model="pageData.zffs" clearable placeholder="支付方式"
                  size="small">
                  <el-option :value="item.val" :label="item.name" v-for="(item,i) in zffs" :key="i"></el-option>
                </el-select>
                <el-select class=" mr-2 " style="width: 10%;" v-model="pageData.zfzt" clearable placeholder="支付状态"
                  size="small">
                  <el-option :value="item.val" :label="item.name" v-for="(item,i) in zfzt" :key="i"></el-option>
                </el-select>
                <el-select class=" mr-2 " style="width: 8%;" v-model="pageData.yczt" clearable placeholder="异常状态"
                  size="small">
                  <el-option :value="item.val" :label="item.name" v-for="(item,i) in yczt" :key="i"></el-option>
                </el-select>
                <el-select class=" mr-2 " style="width: 10%;" v-model="pageData.bdzt" clearable placeholder="补单状态"
                  size="small">
                  <el-option :value="item.val" :label="item.name" v-for="(item,i) in bdzt" :key="i"></el-option>
                </el-select>
                <input placeholder="输入关键字" v-model="pageData.keyword" class="h30 form-control border-blue mr-2 w-10"
                  maxlength="50" />
                <button type="button" class="btn btn-info h30 flexList mr-2 " @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title:exportTitle, type: 'xlsx', module:module,condition:pageData})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type:'dbf', module:module,condition:pageData})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="d-flex justify-content-between mb-3">
              <div class="flexList">
                <label class="mr-2">补单：</label>
                <el-select class=" mr-2 " style="width: 15%;" v-model="pageData2.cxlx" clearable placeholder="查询类型"
                  size="small">
                  <el-option :value="item.val" :label="item.name" v-for="(item,i) in bdzffs" :key="i"></el-option>
                </el-select>

                <button type="button" class="btn btn-info h30 flexList mr-2 " @click="searchBuDan">批量查询补单</button>
                <el-select class=" mr-2 " style="width: 15%;" v-model="pageData2.type" clearable placeholder="补单类型"
                  size="small">
                  <el-option :value="item.val" :label="item.name" v-for="(item,i) in bdgxfs" :key="i"></el-option>
                </el-select>
                <button type="button" class="btn btn-info h30 flexList mr-2 " @click="startBudan">开始批量补单</button>
              </div>
            </div>

            <div class="table-responsive border mt-3">
              <el-checkbox-group v-model="checkList" :max="20">
                <table class="table light-table table-hover  table-bordered">
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 5%;">
                        <el-checkbox :indeterminate="isIndeterminate" @change="checkAll" class="mr-1"></el-checkbox> 序号
                      </th>
                      <th style="width: 10%;">系统号</th>
                      <th style="width: 12%;">支付项目</th>
                      <th style="width: 5%;">报名号</th>
                      <th style="width: 5%;">姓名 </th>
                      <th style="width:8%;">证件号码 </th>
                      <th style="width: 4%;">应缴 </th>
                      <th style="width: 4%;">实收 </th>
                      <th style="width: 7%;">商户</th>
                      <th style="width: 7%;">支付状态</th>
                      <th style="width: 7%;">类型</th>
                      <th style="width: 6%;">支付方式</th>
                      <th style="width: 7%;">补单状态</th>
                      <th style="width: 10%;">时间</th>
                      <th style="width: 3%;">退费</th>
                      <th style="width: 3%;">操作</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item,index) in tableList" :key="index">
                      <td>
                        <el-checkbox :label="item" @change="chooseTrade">
                          {{index+1+(pageData.pageNum-1)*pageData.pageSize}}
                        </el-checkbox>
                      </td>
                      <td>
                        <div :title="item.ddh" v-if="item.ddh" class="line1 white-space-nowrap">订单号：{{item.ddh}}</div>
                        <div :title="item.ddjyh" v-if="item.ddjyh" class="line1 white-space-nowrap">交易号：{{item.ddjyh}}
                        </div>
                        <div :title="item.tkjyh" v-if="item.zfzt=='-1'&&item.tkjyh" class="line1 white-space-nowrap">
                          退款交易号：
                          {{item.tkjyh}}
                        </div>
                      </td>
                      <td>
                        <div :title="item.ssmc">{{item.ssmc}}
                        </div>
                      </td>
                      <td>{{item.dbsid}}</td>
                      <td>
                        <el-button type="text" class="blue-font">{{item.xdrxm}}</el-button>
                      </td>
                      <td>{{item.xdrsfzjh}}</td>
                      <td>{{item.ddzje/100}}</td>
                      <td> {{item.ddzje/100}}</td>
                      <td>
                        <div>{{item.shbm}}</div>
                        <div>

                          {{item.shmc}}
                        </div>
                      </td>

                      <td>
                        <div class="line1 text-warning" v-if="item.zfzt=='-1'" title="已退款交易已关闭">已退款交易已关闭</div>
                        <div class="line1 text-danger" v-if="item.zfzt=='0'" title="未支付">未支付</div>
                        <div class="line1 text-success" v-if="item.zfzt=='1'" title="已支付">已支付</div>
                        <div class="line1 text-warning" v-if="item.zfzt=='2'" title="订单未支付且交易已关闭">订单未支付且交易已关闭
                        </div>
                        <div class="line1 text-warning" v-if="item.zfzt=='03'" title="订单已支付且交易已关闭">订单已支付且交易已关闭
                        </div>

                      </td>
                      <td>{{item.xmmc}}</td>
                      <td>
                        <div class="line1" v-if="item.zffs=='01'">微信</div>
                        <div class="line1" v-if="item.zffs=='02'">支付宝</div>
                        <div class="line1" v-if="item.zffs=='03'">易宝</div>
                        <div class="line1" v-if="item.zffs=='04'">线下_现金</div>
                        <div class="line1" v-if="item.zffs=='06'">线下_单位</div>
                        <div class="line1" v-if="item.zffs=='05'">减免</div>
                        <div class="line1" v-if="item.zffs=='07'">合利宝</div>

                      </td>
                      <td>
                        <el-popover placement="top-start" title="补单信息" width="330" trigger="hover">
                          交易号： {{item.bdh}}<br />
                          交易类型：<span v-if="item.bdlx=='01'">微信</span>
                          <span v-if="item.bdlx=='02'">支付宝</span>
                          <span v-if="item.bdlx== '03'">易宝</span>
                          <span v-if="item.bdlx== '07'">合利宝</span>
                          <br />
                          交易时间： {{item.bdsj}}<br />
                          交易金额:{{item.bdje/100}}元
                          <el-button slot="reference" type="text" class="font-size-12">
                            <span v-if="item.bdzt==1">已补单 </span>
                            <span v-if="item.bdzt==2">已支付未补单 </span>
                            <span v-if="item.bdzt==3"> 未支付不补单 </span>
                          </el-button>
                        </el-popover>


                      </td>
                      <td>
                        <div v-if="item.zfsj">支付时间：{{item.zfsj}}</div>
                        <div v-if="item.modifyTime">通知时间：{{item.modifyTime}}</div>

                      </td>
                      <td class="tab-icon">
                        <i class="iconfont  icon-bianji" v-if="item.zfzt=='1'" title="退费"
                          @click="getReund(item.ddh)"></i>
                      </td>
                      <td class="tab-icon"><i class="iconfont mr-2 icon-antOutline-printer" title="打印"></i>
                      </td>
                      <!-- <td><a href=""><button type="button" class="btn btn-info w-xs h30"><i
                            class="iconfont mr-2 icon-antOutline-printer"></i>收据</button></a>
                    </td> -->
                    </tr>


                  </tbody>

                </table>
              </el-checkbox-group>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <b-modal v-model="showmodal" centered title="退款记录" size="lg" title-class="font-18" hide-footer>
      <div class="grey-time mb-3">说明：当退款交易号为空时，可以申请退款。</div>
      <div class="table-responsive border mt-3">
        <table class="table light-table table-hover  table-bordered">
          <thead class="thead-light">
            <th style="width: 18%;">退款单号</th>
            <th>退款状态</th>
            <th>退款金额</th>
            <th>退款人</th>
            <th>退款时间</th>
            <th>退款交易号</th>
            <th>查询</th>
          </thead>
          <tbody>
            <tr v-for="(obj,index) in refundList" :key="index">
              <td>{{obj.tkdh }}</td>
              <td class="text-center">
                <div class="text-success" v-if="obj.tkzt==1">
                  成功
                </div>
                <div class="text-danger" v-else>
                  失败
                </div>
              </td>
              <td class="text-center">{{obj.tkje/100}}元</td>
              <td>{{obj.createUser}}</td>
              <td>{{obj.tksj}}</td>
              <td>{{obj.tkjyh}}</td>
                <td>
                    <button type="button" class="btn btn-info h30 w-md"
                            @click="getSearch(obj)">查询
                    </button>
                </td>
            </tr>

          </tbody>
        </table>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="OnlineRefund(refundDdh)">申请退款</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 补单弹窗 -->
    <b-modal v-model="bdmodal" centered title="交易查询" size="lg" title-class="font-18" hide-footer>
      <div class="mb-3 d-flex">查询类型：
        <div class="text-info"> <span v-if="pageData2.cxlx=='01'">微信</span>
          <span v-if="pageData2.cxlx=='02'">支付宝</span>
          <span v-if="pageData2.cxlx== '03'">易宝</span>
        </div>

      </div>
      <div class="table-responsive border mt-3">
        <table class="table light-table table-hover  table-bordered">
          <thead class="thead-light">
            <th style="width: 5%;">序号</th>
            <th>订单号</th>
            <th>订单名称</th>
            <th>订单交易号</th>
            <th>订单金额</th>
            <th>订单支付时间</th>
            <th class="text-center">交易状态</th>
          </thead>
          <tbody>
            <tr v-for="(obj,index) in checkList" :key="index">
              <td>{{index+1}}</td>
              <td>{{obj.ddh }}</td>
              <td>{{obj.ssmc}}</td>
              <td>{{obj.jyh}}</td>
              <td class="text-center">{{obj.ddje?obj.ddje/100:""}}元</td>
              <td>{{obj.ddzfsj}}</td>
              <td class="text-center">
                <div class="text-success" v-if="obj.jyzt==1">
                  成功
                </div>
                <div class="text-danger" v-else-if="obj.jyzt==0">
                  失败
                </div>
                <span class="text-info" v-else>正在查询</span>
              </td>

            </tr>

          </tbody>
        </table>
      </div>
      <div class=" mt-3  text-center">
        <button type="button" class="btn btn-secondary h30  w-md" @click="bdmodal = false">取消</button>
      </div>



    </b-modal>

    <!-- 弹窗结束 -->
  </Layout>
</template>
