// 支付

// 支付订单
import {
  getRequest,
  postRequest,
  // putRequest,
  // patchRequest,
  // deleteRequest,
  // downloadRequest
} from "@/libs/axios";
// 订单分页查询
export const getPayList = params => {
  return getRequest("/yethan/pay/trade/listPage", params);
};
// 订单详情
export const tradeDetails = params => {
  return getRequest("/yethan/pay/trade", params);
};
// 在线退费申请
export const createOnlineTrade = params => {
  return getRequest(`/yethan/pay/refund/create?outTradeNo=` + params);
};
// 在线退费查询
export const getOnlineTrade = params => {
  return getRequest("/yethan/pay/refund/refundQuery", params);
};
//更新退款状态
export const UpdateOnlineTrade = params => {
  return getRequest("/yethan/pay/refund/refundQueryDo", params);
};

// 退费信息
export const getRefundTrade = params => {
  return getRequest("/yethan/pay/refund/listAll", params);
};



// 补单查询
export const tradeQuery = params => {
  return getRequest("/yethan/pay/trade/queryOrder", params);
};

// 补单接口
export const needCallbackStatus = params => {
  return postRequest("/yethan/pay/trade/needCallbackStatus", params);
};

// 开始补单
export const stratTrade = (type, outTradeNo) => {
  return postRequest(`/yethan/pay/trade/manualCallback/${type}/${outTradeNo}`);
};
